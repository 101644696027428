import React, { useState } from "react";
import PageHeader from "../components/common/PageHeader";
import { Trans, useTranslation } from "react-i18next";
import Layout from "../components/common/Layout";
import { graphql } from "gatsby";
import Seo from "../components/default/seo";
import useMobileBreakpoint from "../hooks/useMobileBreakpoint";

const inputClasses =
  "w-full bg-inputBackground px-4 py-4 text-sm text-textBlack focus:outline-none min-";

export default function ContactUs() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const isMobile = useMobileBreakpoint();

  const submitForm = async () => {
    const payload = { name, email, message };
    await fetch("/contact-us", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: JSON.stringify(payload),
    })
      .then(() => {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          setName("");
          setEmail("");
          setMessage("");
        }, 3000);
      })
      .catch((error) => console.error(error));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    submitForm();
  };

  const thankYouMessage = (
    <div
      className="mx-auto flex flex-col items-center justify-center bg-gradient-to-r from-primaryBlue to-primaryGreen px-4 py-2
    text-center text-white md:text-left"
    >
      <h2>
        {t("Thank You")},
        {isMobile && <br />}
        {t("Your message has been received.")}
      </h2>
    </div>
  );

  const form = (
    <form className="w-full" onSubmit={handleFormSubmit}>
      <div className="flex flex-col gap-4 sm:flex-row">
        <div className="mb-6 w-full sm:w-1/3">
          <h4 className="mb-4 text-primaryBlue">{t("YOUR NAME")}</h4>
          <input
            placeholder={t("Enter Your Name")}
            type="text"
            className={inputClasses}
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mb-6 w-full sm:w-2/3">
          <h4 className="mb-4 text-primaryBlue">{t("YOUR EMAIL")}</h4>
          <input
            placeholder={t("Enter Your Email")}
            type="email"
            className={inputClasses}
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div className="mb-6 flex w-full flex-col">
        <h4 className="mb-4 text-primaryBlue">{t("MESSAGE")}</h4>
        <textarea
          placeholder={t("Enter Your Message")}
          className={inputClasses}
          rows={5}
          style={{ resize: "none" }}
          value={message}
          required
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      {!success && (
        <button
          type="submit"
          className="w-full border border-primaryBlue py-2 px-8 text-sm font-bold text-primaryBlue transition-all hover:scale-105 hover:bg-white hover:bg-opacity-10 md:w-auto"
        >
          {t("Send")}
        </button>
      )}
      {success && thankYouMessage}
    </form>
  );

  return (
    <Layout>
      <Seo title={t("Contact Us")} lang={language} />
      <PageHeader title={t("Contact Us")} />
      <section className="m-12 flex max-w-5xl flex-row items-start justify-between gap-16 lg:my-24 lg:mx-auto">
        {form}
      </section>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["common", "contactUs"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
